import localforage from "localforage";

type LocalForageItem = {
  expire: number | undefined;
  value: string
}
const localforageExtensions = {
  getItem: async (key: string) => {
    const data = await localforage.getItem<LocalForageItem>(key);

    if (!data) return data;

    if (data.expire && data.expire < Date.now()) {
      localforage.removeItem(key);
      return null;
    }

    return data.value;
  },
  setItem: (key: string, value: string, expire?: number) => {
    // if(expiryDate && typeof expireSeconds === 'number')
    //expireSeconds = Math.round(expire * 1000 + Date.now()); // * 1000 to use seconds

    return localforage.setItem<LocalForageItem>(key, { value, expire });
  },
  removeItem: (key: string) => {
    localforage.removeItem(key, function () { });
  }
};

export default localforageExtensions;
