import { ActionContext } from "vuex";

const state = {
  showChangePassword: false,
  showSessionExpired: false,
  showODAnalyserV1Date: false,
  showTermsAndConditionsModal: false,
  infoModal: {
    show: false,
    title: "Info",
    body: ""
  },
};

type State = typeof state;
type InfoModalInput = { title: string, body: string };

export default {
  namespaced: true,
  state,
  mutations: {
    updateChangePasswordModal(state: State, payload: boolean) {
      state.showChangePassword = payload;
    },
    updateSessionExpiredModal(state: State, payload: boolean) {
      state.showSessionExpired = payload;
    },
    updateOdAnalyserV1DateModal(state: State, payload: boolean) {
      state.showODAnalyserV1Date = payload
    },
    updateTermsAndConditionsModal(state: State, payload: boolean) {
      state.showTermsAndConditionsModal = payload;
    },
    updateInfoModal(state: State, payload: boolean) {
      state.infoModal.show = payload;
    },
    setInfoModalContent(state: State, payload: InfoModalInput) {
      state.infoModal.title = payload.title
      state.infoModal.body = payload.body;
    },
  },
  getters: {
    showSessionExpiredModal(state: State) {
      return state.showSessionExpired;
    },
    showChangePasswordModal(state: State) {
      return state.showChangePassword;
    },
    showODAnalyserV1DateModal(state: State) {
      return state.showODAnalyserV1Date;
    },
    showTermsAndConditionsModal(state: State) {
      return state.showTermsAndConditionsModal;
    },
    showInfoModal(state: State) {
      return state.infoModal.show;
    },
    infoModalContent(state: State) {
      return {
        title: state.infoModal.title,
        body: state.infoModal.body
      }
    }
  },
  actions: {
    createInfoModal(context: ActionContext<State, State>, payload: InfoModalInput) {
      context.commit('setInfoModalContent', payload)
      context.commit('updateInfoModal', true);
    }
  }
}