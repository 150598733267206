export default {
  admin_address: "https://webadmin.peoplemovementportal.com",
  railAnalyticsUrl: "rail.peoplemovementportal.com",
  ckDeltaUrl: "three.peoplemovementportal.com",
  api_address: "https://api.peoplemovementportal.com/gw",
  environment: "Production",
  validationServices: [
    "OD Analyser Validation",
    "Tfl Interchange Validation",
    "Live Station Validation"
  ],
  publicPath: ""
};
