export const DATA_VERSION_KEY = "DATA_VERSION";

//realtime station analyser
export const OD_LAST_STATION = "OD_LAST_STATION";
export const OD_LAST_STATION_CHARTZOOM = "OD_LAST_STATION_CHARTZOOM";
export const OD_LAST_THROUGHPUT_CHARTZOOM = "OD_LAST_THROUGHPUT_CHARTZOOM";
export const OD_STATION_NAMES = "OD_STATION_NAMES";

//od analyser v3
export const OD_STATIONS = "OD_STATIONS";
export const OD_STATION_GROUPS = "OD_STATION_GROUPS";

//West midlands od analyser
export const TFWM_STATIONS = "TFWM_STATIONS";
export const TFWM_STATION_GROUPS = "TFWM_STATION_GROUPS";

//station analayser historic
export const OD_HISTORIC_STATION = "OD_HISTORIC_STATION";
export const OD_HISTORIC_POT = "OD_HISTORIC_POT";
export const OD_HISTORIC_STATION_CHARTZOOM = "OD_HISTORIC_STATION_CHARTZOOM";
export const OD_HISTORIC_THROUGHPUT_CHARTZOOM = "OD_HISTORIC_THROUGHPUT_CHARTZOOM";

//interchange analyser
export const INTERCHANGE_STATIONS = "INTERCHANGE_STATIONS";

//roads
export const ROADS_CORRIDOR = "ROADS_CORRIDOR";
export const ROADS_MAP_OPEN = "ROADS_MAP_OPEN";
export const ROADS_CORRIDORS_OPEN = "ROADS_CORRIDORS_OPEN";
export const ROADS_GRAPHS_OPEN = "ROADS_GRAPHS_OPEN";
