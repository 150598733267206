<template>
  <div class="wrap">
    <!-- BEGIN LOGO -->
    <div id="citianalytics-logo" class="citianalytics-branding cl-logo display-hide">
      <!-- <img src="./citilogik_assets/images/app-logo-on-dark.png" alt="" /> -->
      <!-- <img src="../assets/images/VF_Citi_analytics_LOGO.png" style="max-height: 140px;max-width:390px" alt="" /> -->
    </div>
    <div id="railanalytics-logo" class="railanalytics-branding cl-logo display-hide">
      <!-- <img src="../assets/images/VF_Rail_analytics_LOGO.png" style="max-height: 110px;max-width:390px" alt="" /> -->
    </div>
    <div id="ckdelta-logo" class="ckdelta-branding cl-logo display-hide">
      <!-- <img src="../assets/images/VF_CkDelta_Citi_analytics_LOGO.png" style="max-height: 110px" alt="" /> -->
    </div>
    <!-- END LOGO -->
    <!-- BEGIN ENVIRONMENT -->
    <div class="environment">
      <span id="LoginEnvironment"></span>
    </div>
    <!-- END ENVIRONMENT -->
    <!-- BEGIN LOGIN -->
    <div class="content">
      <div id="unsupported-browser-message" class="alert alert-danger display-hide">
        <span> You are using an unsupported web browser. </span>
        <br /><br />
        <span>Please switch to a recent version of Chrome, Safari, Firefox, or Edge.</span>
      </div>
      <div id="loginMessage" class="alert alert-info display-hide">
        <span></span>
      </div>
      <!-- BEGIN LOGIN FORM -->
      <div id="confirm-email-response" class="alert display-hide">
        <button class="close" data-dismiss="alert"></button>
        <span id="confirmation-message"></span>
      </div>
      <form class="login-form" action="/" method="get">
        <h3 class="form-title">Login to your account</h3>
        <div id="login-error" class="alert alert-danger display-hide">
          <button class="close" data-dismiss="alert"></button>
          <span> Enter a valid username and password. </span>
        </div>
        <div class="form-group">
          <!--ie8, ie9 does not support html5 placeholder, so we just show field title for that-->
          <label class="control-label visible-ie8 visible-ie9">Username</label>
          <div class="input-icon">
            <i class="fa fa-user"></i>
            <input id="Username" class="form-control placeholder-no-fix" type="text" autocomplete="off" placeholder="Username" name="Username" />
          </div>
        </div>
        <div class="form-group">
          <label class="control-label visible-ie8 visible-ie9">Password</label>
          <div class="input-icon">
            <i class="fa fa-lock"></i>
            <input id="Password" class="form-control placeholder-no-fix" type="password" autocomplete="off" placeholder="Password" name="Password" />
          </div>
        </div>
        <div class="form-actions">
          <label class="rememberme mt-checkbox mt-checkbox-outline">
            <input type="checkbox" name="remember" value="1" /> Remember me
            <span></span>
          </label>
          <button type="submit" class="btn voda-btn-success pull-right">Login</button>
        </div>

        <div class="forget-password">
          <h4>Forgot your password ?</h4>
          <p>
            no worries, click
            <a href="javascript:;" id="forget-password"> here </a> to reset your password.
          </p>
        </div>
        <!--   <div class="create-account">
                        <p> Don't have an account yet ?&nbsp;
                            <a href="javascript:;" id="register-btn"> Create an account </a>
                        </p>
                    </div> -->
      </form>
      <!-- END LOGIN FORM -->

      <!-- BEGIN FORGOT PASSWORD FORM -->
      <form class="forget-form" action="#" method="post">
        <div class="alert alert-danger display-hide">
          <button class="close" data-dismiss="alert"></button>
          <span> Please enter a valid e-mail address. </span>
        </div>
        <div class="alert alert-success display-hide">
          <button class="close" data-dismiss="alert"></button>
          <span> Password reset requested, if the e-mail address is registered on the system you will recieve an e-mail with password reset instructions. </span>
        </div>
        <h3>Forget Password ?</h3>
        <p>Enter your e-mail address below to reset your password.</p>
        <div class="form-group">
          <div class="input-icon">
            <i class="fa fa-envelope"></i>
            <input class="form-control placeholder-no-fix" type="text" autocomplete="off" placeholder="Email" name="emailAddress" />
          </div>
          <input id="tenancy" name="tenancyName" type="hidden" value="default" /><!-- todo - hard coded tanancy name-->
        </div>
        <div class="form-actions">
          <button type="button" id="back-btn" class="btn red btn-outline">Back</button>
          <button type="submit" class="btn voda-btn-success pull-right">Submit</button>
        </div>
      </form>
      <!-- END FORGOT PASSWORD FORM -->
    </div>
    <!-- END LOGIN -->
    <!-- BEGIN POWERED BY LOGO -->
    <div id="citianalytics-bottom-branding" class="citianalytics-branding ckdelta-branding cl-logo display-hide">
      <a href="https://www.citilogik.com/">
        <!-- <img src="./citilogik_assets/images/app-logo-on-dark.png" alt="" /> -->
        <img src="../assets/images/powered_by_CL-WHITE.png" style="max-height: 120px;max-width:390px" alt="" />
      </a>
    </div>

    <div id="railanalytics-bottom-branding" class="railanalytics-branding cl-logo display-hide">
      <img src="../assets/images/RailAnalytics_Login_Logos.png" style="width: 85%" alt="" />
    </div>
    <!-- END POWERED BY LOGO  -->
    <!-- BEGIN COPYRIGHT -->
    <div class="copyright">
      <p class="text-center">
        <span v-html="copyrightText"></span>
      </p>
    </div>
  </div>
</template>

<script>
import { loginRequest } from "../js/util/requestHelper";
import { getQueryParams, isRailAnalytics } from "../js/util/urlHelper";
import envConfig from "../js/config/envConfig";
import { pagePreLoad } from "../js/util/pageLoad";

export default {
  name: "App",
  computed: {
    copyrightText() {
      const year = new Date().getFullYear();
      return `${year} &copy; Citi Logik Ltd`;
    },
    backgroundImages() {
      if (isRailAnalytics()) {
        return [
          { src: require("../assets/images/rail_login_0.png") },
          { src: require("../assets/images/rail_login_1.jpg") },
          { src: require("../assets/images/rail_login_2.png") },
          { src: require("../assets/images/rail_login_3.jpg") }
        ];
      } else {
        return [
          { src: require("../assets/images/login_image_0.jpg") },
          { src: require("../assets/images/login_image_2.jpg") },
          { src: require("../assets/images/login_image_3.jpg") }
        ];
      }
    }
  },
  mounted() {
    //TODO - Refactor into proper Vue
    const backgroundImages = this.backgroundImages;
    const Login = (function () {
      var handleLogin = function () {
        $(".login-form").validate({
          errorElement: "span", //default input error message container
          errorClass: "help-block", // default input error message class
          focusInvalid: false, // do not focus the last invalid input
          ignore: "", // validate all fields including form hidden input
          rules: {
            Username: {
              required: true
            },
            Password: {
              required: true
            },
            remember: {
              required: false
            }
          },

          messages: {
            Username: {
              required: "Username is required."
            },
            Password: {
              required: "Password is required."
            }
          },

          invalidHandler: function () {
            //display error alert on form submit
            $("#login-error", $(".login-form")).show();
          },

          highlight: function (element) {
            // hightlight error inputs
            $(element).closest(".form-group").addClass("has-error"); // set error class to the control group
          },

          success: function (label) {
            label.closest(".form-group").removeClass("has-error");
            label.remove();
          },

          errorPlacement: function (error, element) {
            error.insertAfter(element.closest(".input-icon"));
          },

          submitHandler: function () {
            var username = document.getElementById("Username").value;
            var password = document.getElementById("Password").value;

            //remove name attribute, required for jquery valdation, but dont want submitting with form
            document.getElementById("Username").removeAttribute("name");
            document.getElementById("Password").removeAttribute("name");

            loginRequest(username, password)
              .then(() => {
                window.location = "/";
              })
              .catch(() => {
                $("#login-error", $(".login-form")).show();
                document.getElementById("Username").setAttribute("name", "Username");
                document.getElementById("Password").setAttribute("name", "Password");
              });
          }
        });

        $(".login-form input").keypress(function (e) {
          if (e.which == 13) {
            if ($(".login-form").validate().form()) {
              $(".login-form").submit();
            }
            return false;
          }
        });
      };

      var handleForgetPassword = function () {
        $(".forget-form").validate({
          errorElement: "span", //default input error message container
          errorClass: "help-block", // default input error message class
          focusInvalid: false, // do not focus the last invalid input
          ignore: "",
          rules: {
            emailAddress: {
              required: true,
              email: true
            }
          },

          messages: {
            emailAddress: {
              required: "Email is required."
            }
          },

          invalidHandler: function () {
            //display error alert on form submit
            $("#login-error", $(".forget-form")).show();
          },

          highlight: function (element) {
            // hightlight error inputs
            $(element).closest(".form-group").addClass("has-error"); // set error class to the control group
          },

          success: function (label) {
            label.closest(".form-group").removeClass("has-error");
            label.remove();
          },

          errorPlacement: function (error, element) {
            error.insertAfter(element.closest(".input-icon"));
          },

          submitHandler: function (form) {
            $.ajax({
              type: "POST",
              url: envConfig.admin_address + "/Account/SendPasswordResetLink",
              data: $(form).serialize(),
              success: function () {
                $(".alert-success", $(".forget-form")).show();
              }
            });
            return false; // required to block normal submit since we used ajax
          }
        });

        $(".forget-form input").keypress(function (e) {
          if (e.which == 13) {
            if ($(".forget-form").validate().form()) {
              $(".forget-form").submit();
            }
            return false;
          }
        });

        $("#forget-password").click(function () {
          $(".login-form").hide();
          $(".forget-form").show();
        });

        $("#back-btn").click(function () {
          $(".login-form").show();
          $(".forget-form").hide();
        });
      };

      var handleRegister = function () {
        function format(state) {
          if (!state.id) {
            return state.text;
          }
          var $state = $('<span><img src="../assets/global/img/flags/' + state.element.value.toLowerCase() + '.png" class="img-flag" /> ' + state.text + "</span>");

          return $state;
        }

        if ($().select2 && $("#country_list").size() > 0) {
          $("#country_list").select2({
            placeholder: '<i class="fa fa-map-marker"></i>&nbsp;Select a Country',
            templateResult: format,
            templateSelection: format,
            width: "auto",
            escapeMarkup: function (m) {
              return m;
            }
          });

          $("#country_list").change(function () {
            $(".register-form").validate().element($(this)); //revalidate the chosen dropdown value and show error or success message for the input
          });
        }

        $(".register-form").validate({
          errorElement: "span", //default input error message container
          errorClass: "help-block", // default input error message class
          focusInvalid: false, // do not focus the last invalid input
          ignore: "",
          rules: {
            fullname: {
              required: true
            },
            email: {
              required: true,
              email: true
            },
            address: {
              required: true
            },
            city: {
              required: true
            },
            country: {
              required: true
            },

            username: {
              required: true
            },
            password: {
              required: true
            },
            rpassword: {
              equalTo: "#register_password"
            },

            tnc: {
              required: true
            }
          },

          messages: {
            // custom messages for radio buttons and checkboxes
            tnc: {
              required: "Please accept TNC first."
            }
          },

          invalidHandler: function () {
            //display error alert on form submit
          },

          highlight: function (element) {
            // hightlight error inputs
            $(element).closest(".form-group").addClass("has-error"); // set error class to the control group
          },

          success: function (label) {
            label.closest(".form-group").removeClass("has-error");
            label.remove();
          },

          errorPlacement: function (error, element) {
            if (element.attr("name") == "tnc") {
              // insert checkbox errors after the container
              error.insertAfter($("#register_tnc_error"));
            } else if (element.closest(".input-icon").size() === 1) {
              error.insertAfter(element.closest(".input-icon"));
            } else {
              error.insertAfter(element);
            }
          },

          submitHandler: function (form) {
            form.submit();
          }
        });

        $(".register-form input").keypress(function (e) {
          if (e.which == 13) {
            if ($(".register-form").validate().form()) {
              $(".register-form").submit();
            }
            return false;
          }
        });

        jQuery("#register-btn").click(function () {
          jQuery(".login-form").hide();
          jQuery(".register-form").show();
        });

        jQuery("#register-back-btn").click(function () {
          jQuery(".login-form").show();
          jQuery(".register-form").hide();
        });
      };

      var handleEmailConfirmation = function () {
        let queryParams = getQueryParams();
        let confirmEmailUrl = `${envConfig.admin_address}/Account/EmailConfirmation?confirmationCode=${encodeURIComponent(
          queryParams["confirmationCode"]
        )}&userId=${encodeURIComponent(queryParams["userId"])}&tenantId=${encodeURIComponent(queryParams["tenantId"])}`;

        $.ajax({
          type: "GET",
          url: confirmEmailUrl,
          success: function () {
            $("#confirm-email-response").show();
            $("#confirm-email-response").addClass("alert-success");
            $("#confirm-email-response #confirmation-message").text("Your email address was confirmed successfully");
          },

          error: function () {
            $("#confirm-email-response").show();
            $("#confirm-email-response").addClass("alert-danger");
            $("#confirm-email-response #confirmation-message").text(`Unable to confirm your email address`);
          }
        });
      };

      return {
        //main function to initiate the module
        init: function () {
          pagePreLoad();

          if (getQueryParams()["message"]) {
            $("#loginMessage span").text(decodeURIComponent(getQueryParams()["message"]));
            $("#loginMessage").show();
          }

          if (getQueryParams()["confirmationCode"]) {
            handleEmailConfirmation();
          }
          handleLogin();
          handleForgetPassword();
          handleRegister();

          // init background slide images
          /*
          $.backstretch([
              "citilogik_public_assets/images/login_image_0.jpg",
              "citilogik_public_assets/images/login_image_2.jpg",
              "citilogik_public_assets/images/login_image_3.jpg"
              ], {
            fade: 1000,
                duration: 8000
            }
        );
        */

          $("body").vegas({
            delay: 10000,
            timer: false,
            shuffle: true,
            firstTransition: "fade",
            firstTransitionDuration: 1000,
            transitionDuration: 1000,
            slides: backgroundImages
          });

          if (envConfig.environment != "Production") {
            $("#LoginEnvironment").text(envConfig.environment.toUpperCase());
          }
        }
      };
    })();

    $(() => {
      Login.init();
    });
  }
};
</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/vegas/2.5.1/vegas.min.css";
@import "../assets/global/css/components.min.css";
@import "../assets/css/login-4.min.css";
@import "../assets/css/cl-login-4.css";
@import "../assets/css/citilogik_main.css";
</style>
