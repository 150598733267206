const state = {
  sidebarClosed: false
}
type State = typeof state;

export default {
  namespaced: true,
  state: {
    sidebarClosed: false,
  },
  mutations: {
    toggleSidebar(state: State) {
      state.sidebarClosed = !state.sidebarClosed;
    }
  },
  getters: {
    sidebarClosed(state: State) {
      return state.sidebarClosed;
    }
  }
}