const state = {
  stationNames: [] as string[],
  selectedStation: -1
}

type State = typeof state;

export default {
  namespaced: true,
  state,
  mutations: {
    updateStationNames(state: State, payload: string[]) {
      state.stationNames = payload;
    },
    updateSelectedStation(state: State, payload: number) {
      state.selectedStation = payload;
    },
  },
  getters: {
    stationNames(state: State) {
      return state.stationNames;
    },
    selectedStation(state: State) {
      return state.selectedStation;
    }
  }
}