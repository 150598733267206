import { setCookie, getCookie } from "./cookieHelper";
import envConfig from "../config/envConfig";
import { isRailAnalytics } from "./urlHelper";
import { DATA_VERSION_KEY } from "./storageConstants";
import localforageExtensions from "./localForageExtensions";
import store from "@/js/store/store";

import { ref } from "vue";

export function loginRequest(usernameOrEmail: string, password: string) {
  const authenticateModel = {
    UserNameOrEmailAddress: usernameOrEmail,
    Password: password
  };

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Abp.TenantId", "1");
  headers.append("Cache-Control", "no-cache");

  return fetch(envConfig.admin_address + "/api/TokenAuth/Authenticate", {
    headers: headers,
    method: "POST",
    body: JSON.stringify(authenticateModel)
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response.status);
    })
    .then(data => {
      console.log("Authentication Successful");

      if (data.result.userType == 1 && !isRailAnalytics()) {
        console.log("redirecting to railanalytics");
        window.location.href =
          "http://" +
          envConfig.railAnalyticsUrl +
          `/login.html?message=${encodeURIComponent(
            `Please login via ${envConfig.railAnalyticsUrl}`
          )}`;

        return Promise.reject();
      }

      const expirySeconds = data.result.expireInSeconds;
      const expiryDate = new Date();
      expiryDate.setTime(expiryDate.getTime() + expirySeconds * 1000);
      setCookie("expiryDate", expiryDate.getTime(), expirySeconds);

      const accessToken = data.result.accessToken;
      setCookie("token_id", accessToken, expirySeconds);

      const userId = data.result.userId;
      setCookie("userId", userId, expirySeconds);

      const userName = data.result.userName;
      setCookie("userName", userName, expirySeconds);

      const firstName = data.result.firstName;
      setCookie("firstName", firstName, expirySeconds);

      const surname = data.result.surname;
      setCookie("surname", surname, expirySeconds);

      const emailAddress = data.result.emailAddress;
      setCookie("emailAddress", emailAddress, expirySeconds);

      const tenancyId = data.result.tenancyId;
      setCookie("tenancyId", tenancyId, expirySeconds);

      return Promise.resolve();
    });
}

const ongoingRequestUrls = ref<string[]>([]);

function initRequest(url: string) {
  document.body.style.cursor = "wait";
  ongoingRequestUrls.value.push(url);
}

function finRequest(url: string) {
  ongoingRequestUrls.value = ongoingRequestUrls.value.filter(x => x !== url);
  if (ongoingRequestUrls.value.length === 0) {
    document.body.style.cursor = "auto";
  }
}

export const authenticatedRequest = (url: string, requestInit: RequestInit) => {
  const requestConfig = { ...requestInit };
  requestConfig.headers = new Headers();
  if (requestInit.headers !== undefined) {
    //ignore this TS mumbo jumbo. Not sure why but the api has been designed to return string sometimes...
    for (const pair of (requestInit.headers.entries as () => IterableIterator<[string, string]>)()) {
      requestConfig.headers.append(pair[0], pair[1]);
    }
  }
  const tokenId = getCookie("token_id");
  if (!tokenId) {
    store.commit("modals/updateSessionExpiredModal", true);
    return Promise.reject(401);
  }
  
  if (url.includes(envConfig.admin_address)) {
    requestConfig.headers.append("Authorization", `Bearer ${tokenId}`);
  } else {
    requestConfig.headers.append("AuthToken", tokenId);
  }

  initRequest(url);
  return fetch(url, requestConfig)
    .then(res => {
      if (res.status == 401 || res.status == 403) {
        store.commit("modals/updateSessionExpiredModal", true);
        return Promise.reject(res.status);
      }
      return Promise.resolve(res);
    })
    .catch(err => {
      console.error(err);
      return Promise.reject(err);
    }).finally(() => {
      finRequest(url);
    });
};

export function applyValidate(url: string, serviceName: string) {
  if (envConfig.validationServices.includes(serviceName)) {
    if (url.includes("?")) {
      url += "&validationOverride=true";
    } else {
      url += "?validationOverride=true";
    }
  }

  return url;
}

export async function getDataVersion(serviceName: string) {
  const url = envConfig.api_address.replace(
    "/gw",
    `/networkrail/dataversion?service=${serviceName}`
  );
  const headers = new Headers();

  await authenticatedRequest(url, { headers })
    .then(res => {
      if (res.ok) {
        return res;
      } else {
        throw `Unable to fetch data version ${res.status}`;
      }
    })
    .then(response => response.json())
    .then(async data => {
      await localforageExtensions.setItem(DATA_VERSION_KEY, data.version);
    });
}
