import { createStore } from "vuex";
import realtimeStations from "./modules/realtimeStations";
import root from "./modules/root";
import modals from "./modules/modals";
import ui from "./modules/ui";

const store = createStore({
  modules: {
    root,
    realtimeStations,
    modals,
    ui
  }
});

export default store;
