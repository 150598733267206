import { isRailAnalytics, isCkDelta } from "./urlHelper";

function updateTitleAndFavicon() {
  if (isRailAnalytics()) {
    const title = document.querySelector<HTMLTitleElement>("head title")!;
    const favicon = document.querySelector<HTMLLinkElement>('link[rel="shortcut icon"]')!;

    title.text = title.text.replace("Citi", "Rail");
    favicon.setAttribute("href", "railfavicon.ico");
  }
}
function showAll(elements: NodeListOf<HTMLElement>) {
  elements.forEach(elem => {
    elem.style.display = "block";
  });
}

function loadPageBranding() {
  if (isRailAnalytics()) {
    showAll(document.querySelectorAll<HTMLElement>(".railanalytics-branding"));
  } else if (isCkDelta()) {
    showAll(document.querySelectorAll<HTMLElement>(".ckdelta-branding"));
  } else {
    showAll(document.querySelectorAll<HTMLElement>(".citianalytics-branding"));
  }
}

export function pagePreLoad() {
  updateTitleAndFavicon();
  loadPageBranding();
}
