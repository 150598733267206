import { deleteAllCookies } from "@/js/util/cookieHelper";
import localForageExtensions from "@/js/util/localForageExtensions";
import * as storageConsts from "@/js/util/storageConstants";

const state = {
  currentClient: {},
  currentService: {},
  engineVersion: null as string | null,
  servicesLoaded: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  serviceLoadCallback: () => { },
  queuedPage: "/"
}

type State = typeof state;
type ServiceConfigInput = { currentService: Object, currentClient: Object }
type QueuePageLoadInput = { callback: () => void, path: string }

export default {
  state,
  mutations: {
    updateServiceConfig(state: State, payload: ServiceConfigInput) {
      state.currentService = payload.currentService;
      state.currentClient = payload.currentClient;

      if (!state.servicesLoaded) {
        state.servicesLoaded = true;
        state.serviceLoadCallback();
      }
    },
    updateEngineVersion(state: State, payload: string | null) {
      state.engineVersion = payload
    },
    queuePageLoad(state: State, payload: QueuePageLoadInput) {
      state.serviceLoadCallback = payload.callback;
      state.queuedPage = payload.path;
      if (state.servicesLoaded) payload.callback();
    }
  },
  getters: {
    currentClient(state: State) {
      return state.currentClient;
    },
    currentService(state: State) {
      return state.currentService;
    },
    engineVersion(state: State) {
      return state.engineVersion || "Unknown";
    },
    servicesLoaded(state: State) {
      return state.servicesLoaded;
    },
    queuedPage(state: State) {
      return state.queuedPage;
    }
  },
  actions: {
    logout() {
      deleteAllCookies();

      Object.values(storageConsts).forEach(key => {
        localForageExtensions.removeItem(key);
      });

      window.location.href = "login.html";
    }
  }
}